<template>
  <div class="flex flex-col gap-5 h-full overflow-auto">
    <div class="flex flex-col gap-6">
      <div
        class="flex justify-between gap-7 bg-blue-sea-75 px-6 py-7 relative mb-8">
        <div class="flex flex-col gap-2 w-full">
          <label class="text-secondary-900 font-simplerBold font-black text-xl">
            {{ $t('course_library.courses_library') }}
          </label>
          <p
            class="max-w-[522px] font-simplerRegular text-sm text-secondary-800">
            {{ $t('course_library.inspiration_quote') }}
          </p>
        </div>
        <img class="absolute left-14 top-0" :src="Books" alt="books" />
      </div>
      <div class="flex items-center gap-3">
        <BaseSelect
          class="bg-white w-40 rounded-4"
          :options="disciplinesFilters"
          default-value="כל המקצועות"
          :current-value="disciplineFilter ?? ''"
          @select="value => (disciplineFilter = value)"/>
        <BaseSelect
          class="bg-white w-40 rounded-4"
          :options="gradesFilters"
          :default-value="!gradeFilter ? 'כל הכיתות' : ''"
          :current-value="gradeFilter ?? ''"
          @select="value => (gradeFilter = value)"/>
        <BaseSelect
          class="bg-white w-40 rounded-4"
          :options="['שער 1 - מפגש', 'שער 2 - עצמאות', 'שער 3 - גילוי']"
          default-value="כל השערים"
          :current-value="gateFilter ?? ''"
          @select="value => (gateFilter = value)"/>
        <BaseInput
          v-model="courseQuery"
          type="search"
          class="base-input w-60 !flex-grow-0 rounded-4"
          placeholder="חיפוש לפי שם קורס">
          <template #prefix>
            <Search class="h-4 w-4 stroke-secondary-400" />
          </template>
        </BaseInput>
      </div>
    </div>

    <div class="flex-1 flex flex-col">
      <ScrollableContainer
        v-if="filteredLibrary?.length && !loading"
        hidescrollbar>
        <div class="grid grid-cols-1 xs:grid-cols-2 gap-3 m-1.5">
          <CourseCard
            v-for="course in filteredLibrary"
            :key="course.id"
            type="library"
            :course="course"/>
        </div>
      </ScrollableContainer>
      <div
        v-else-if="!coursesStore.courses.length || loading"
        class="grid grid-cols-1 xs:grid-cols-2 gap-3 m-1.5">
        <CourseCard
          v-for="(course, index) in Array.from(new Array(8))"
          :key="index"
          type="library"
          :loading="true"
          :course="null"/>
      </div>
      <div
        v-else
        class="w-full flex flex-1 flex-col justify-center items-center gap-2.5">
        <ConstructionCone />
        <text_xs>{{ $t('course_page.no_results') }}</text_xs>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useCourseStore } from '/@/app/store/course';
import { useSurveyStore } from '/@/app/store/surveys';
import { BaseInput } from '@nwire/amit-design-system';
import { CourseCard } from '@amit/components';
import { Search } from 'lucide-vue-next';
import ConstructionCone from '/@/assets/construction-cone.svg?component';
import Books from '/@/assets/books.svg?url';
import BaseSelect from './partials/BaseSelect.vue';
// import { useI18n } from 'vue-i18n';
// import ScrollableContainer from './partials/ScrollableContainer.vue';
// import LoadingSpinner from './partials/LoadingSpinner.vue';
import { useAccountStore } from '/@/app/store/account';
import { useNavigation } from '@amit/layout';
import ScrollableContainer from '/@/views/playground/partials/ScrollableContainer.vue';

const accountStore = useAccountStore();
const coursesStore = useCourseStore();
const surveysStore = useSurveyStore();
// const { t } = useI18n();
const gateFilter = ref(null);
const gradeFilter = ref(null);
const loading = ref(false);
const disciplineFilter = ref(null);
const courseQuery = ref('');

const setFilter = key => {
  let filters = [];
  if (courses.value.length > 0) {
    courses.value.forEach(course => {
      if (course[key]) {
        course[key].forEach(filter => {
          if (!filters.includes(filter)) {
            filters.push(filter);
          }
        });
      }
    });
  }
  if (surveysStore.surveys.length > 0) {
    surveysStore.surveys.forEach(survey => {
      if (survey[key]) {
        survey[key].forEach(filter => {
          if (!filters.includes(filter)) {
            filters.push(filter);
          }
        });
      }
    });
  }
  return filters;
};

//TODO - REMOVE ME
const courses = computed(() => {
  return accountStore.user?.id !== '655f4951b2ac174aff9ac009'
    ? coursesStore.courses
    : coursesStore.courses.filter(course =>
        [
          '63a1001b6376e0feb759b966',
          '65676f5905be00fb1d90a29d',
          '656f065b083aebccaf087360',
        ].includes(course.id),
      );
});

const library = computed(() => [
  ...(courses.value ?? []),
  ...(surveysStore.surveys ?? []),
]);

const disciplinesFilters = computed(() => setFilter('disciplines'));
const gradesFilters = computed(() => {
  const filters = setFilter('grades');

  return filters.sort((a: string, b: string) => {
    if (a === 'כל הכיתות') return -1;
    if (b === 'כל הכיתות') return 1;

    return a.localeCompare(b, 'he');
  });
});

const filteredLibrary = computed(() =>
  library.value.filter(course => {
    const inSearch =
      !courseQuery.value.length ||
      course.caption.toLowerCase().includes(courseQuery.value.toLowerCase());
    const inDiscipline =
      !disciplineFilter.value ||
      course?.disciplines?.includes(disciplineFilter.value ?? '');
    const inGate =
      !gateFilter.value ||
      course?.gates?.some(gate => gate.includes(gateFilter.value ?? ''));
    const inGrade =
      !gradeFilter.value ||
      course?.grades?.some(grade => grade.includes(gradeFilter.value ?? ''));

    return inSearch && inDiscipline && inGate && inGrade;
  }),
);

const fetchData = async () => {
  try {
    loading.value = true;
    await Promise.all([
      coursesStore.fetchCourses(),
      surveysStore.fetchSurveys(),
    ]);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  fetchData();
});

useNavigation([]);
</script>

<style>
.cards-block {
  display: grid;
  grid-template-columns: repeat(auto-fill, 256px);
  gap: 10px;
}

.course-card:hover {
  box-shadow:
    0px 1px 6px 2px rgba(12, 74, 110, 0.05),
    0px 2px 10px 0px rgba(12, 74, 110, 0.1);
}
</style>
